.word {
  margin: auto;
  font-size: xxx-large;
  display: block;
  min-width: 370px;
  text-align: left;
}

.name {
  margin-top: 56px;
}

.handwrittenUnderline {
  position: relative;
}

.handwrittenUnderline::before {
  content: "";
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 100%;
  height: 5px;
  background: linear-gradient(to right, #39b2aa, transparent);
  transform: skewX(-10deg);
  opacity: 0;
  animation: animateUnderline 1s forwards;
  animation-delay: 2s;

}

.text {
  position: relative;
  z-index: 1;
}

.line {
  stroke-dasharray: 300%;
  position: absolute;
  animation: fillLine 1s linear;
}

@keyframes animateUnderline {
  0% {
    opacity: 0;
    width: 0%;
  }
  100% {
    opacity: 1;
    width: 100%;
  }
}

@keyframes fillLine {
  0% {
    stroke-dashoffset: 300%;
  }
  100% {
    stroke-dashoffset: 0%;
  }
}
