.email {
  position: fixed;
  display: flex;
  justify-content: center;
  top: 15vh;
  left: 10%;
  width: 80%;
  height: 80vh;
  z-index: 4;
}

.emailContent {
  display: grid;
  grid-template-columns: 50% 50%;
  /* flex-wrap: wrap; */
  border-radius: 10px;
  overflow: hidden;
  text-align: center;
  box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.3);
  background-color: white;
  /* transform: translate(-50%, -50%); */
  width: 50%;
  height: 80%;
  animation: slide-down 500ms ease-out forwards;
}

@keyframes slide-down {
  from {
    /* height: 180px; */
    opacity: 0;
    /* transform: rotate(3deg); */
    transform: translateY(-3rem);
  }
  to {
    /* width: 50%; */
    opacity: 1;
    /* transform: rotate(0); */
    transform: translateY(0);
  }
}

.emailMap {
  height: 160%;
}

.emailForm {
  padding: 10px;
  text-align: left;
}

.emailForm label {
  display: flex;
  /* border: 1px solid red; */
  margin-bottom: 5px;
}

.emailForm input {
  width: 100%;
  height: 30px;
  margin-bottom: 5px;
}
.emailForm textarea {
  width: 100%;
  height: 100px;
  margin-bottom: 5px;
}

.emailSend {
  width: 52px;
  margin-left: 40%;
}

@media (max-width: 50rem) {
  .email {
    height: 120vh;
  }
  .emailContent {
    grid-template-columns: auto;
    grid-template-rows: 30% 70%;
    width: 90%;
    height: 60%;
    overflow: scroll;
  }
}
