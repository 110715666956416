.skill {
  text-align: center;
}

.skill h1 {
  margin-top: 50px;
}

.reactLogo {
  margin-bottom: 15px;
}

.skillGrid {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  gap: 30px;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.skillItem {
  background-color: rgba(245, 245, 245);
  width: 150px;
  height: 200px;
  border-radius: 5px;

}

.skillItem img {
  margin-top: 15px;
}

.ReactTitle {
  background-color: red;
}

@media (max-width: 40rem) {

  .skillGrid {
    grid-template-columns: auto auto;
    gap: 10px;
  }

  .skillItem {
    width: 140px;
    height: 180px;
  }

  .skillItem img {
    width: 100px;
  }
}
