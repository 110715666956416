.workExp {
  /* min-height: 40vh; */
  /* width: 90%; */
  margin: auto;
  display: grid;
  gap: 2rem;
  padding: 2rem;
  background: rgba(245, 245, 245);
  /* height: 1000px; */
  /* overflow: auto; */
  
}

/* .workExp::-webkit-scrollbar {
  width: 0;
  background-color: transparent;
} */

.workTitle {
  border: none;
  text-align: center;
}

.expImage {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
}

.expImage img {
  width: 200px;
  border-radius: 5px;
}

.twoButtons {
  display: flex;
  /* gap: 10px; */
  justify-content: center;
}
  
.container {
  transform: translate(0%, 0%);
  border-radius: 10px;
  margin-left: 5px;
  margin-right: 5px;
}

.center {
  width: 180px;
  height: 50px;
  /* position: absolute; */
  background-color: lightseagreen;
  border-radius: 10px;
}

.btn {
  width: 180px;
  height: 50px;
  cursor: pointer;
  background: transparent;
  border: 1px solid #91C9FF;
  outline: none;
  transition: 1s ease-in-out;
  border-radius: 10px;
}

svg {
  position: absolute;
  left: 0;
  top: 0;
  fill: none;
  stroke: #fff;
  stroke-dasharray: 150 480;
  stroke-dashoffset: 150;
  transition: 1s ease-in-out;
}

.btn:hover {
  transition: 1s ease-in-out;
  background: #f9c762;
}

.btn:hover svg {
  stroke-dashoffset: -480;
}

.btn span {
  color: white;
  /* font-size: 18px;
  font-weight: 100; */
}

@media (max-width: 40rem) {
  .twoButtons {
    flex-direction: column;
    display: flex;
    row-gap: 2px;
    align-items: center;
  }
}
