.about {
  margin: auto;
  display: grid;
  padding: 2rem;
  background: rgba(245, 245, 245);
  text-align: center;
  height: 45rem;
}

.descirption {
  display: flex;
}

.wordsPart {
  width: 500px;
  text-align: left;
  margin-left: 10%;
}

.profile {
  width: 300px;
  height: 300px;
  margin-left: 20%;
}

@media (max-width: 40rem) {
  .descirption {
    flex-direction: column;
    display: flex;
    row-gap: 10px;
    align-items: center;
  }

  .profile {
    margin: auto;
  }

  .wordsPart {
    width: 300px;
    margin: auto;
  }
}

