.header {
  /* position: absolute; */
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  /* z-index: 1; */
}

.background {
  position: relative;
  display: flex;
  width: 230px;
  height: 50px;
  background: lightseagreen;
  border-radius: 100px;
  border: 3px solid lightgray;
  transition: 0.3s ease-in-out;
}

.list {
  position: absolute;
  display: flex;
  gap: 0.5rem;
  list-style-type: none;
  padding: 0;
  /* top: 4.5%; */
  z-index: 1;
}

.list a {
  padding: 0.7rem 1.8rem;
  border-radius: 103.19px;
  text-decoration: none;
}

/* .listBackground {
  position: absolute;
  padding: 0.7rem 1.8rem;
  width: 30px;
  border-radius: 100px;
  background-color: red;
  z-index: 3;
} */

.active {
  background-color: #f9c762;
}

/* .background:hover {
  height: 300px;
  border-radius: 20px;
} */
/* 
@media (max-width: 40rem) {
  .list {
    top: 4%;
  }
} */
