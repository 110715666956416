.main {
  display: flex;
  padding-bottom: 50px;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  list-style: none;
  /* margin-left: 10%; */
  min-width: 450px;
  position: relative;
  margin: 0 auto;
  padding: 0 10px;
}

.list img {
  margin-right: 20px;
  margin-top: 20px;
  overflow: hidden;
  box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease-out;
}

.achen1 {
  width: 180px;
  transform: rotate(-3deg);
}

.achen1:hover {
  transform: rotate(-3deg) scale(1.05);
}

.achen2 {
  width: 150px;
  transform: rotate(3deg);
  margin-left: 50px;
}

.achen2:hover {
  transform: rotate(3deg) scale(1.05);
}

.achen3 {
  width: 180px;
  transform: rotate(-3deg);
}

.achen3:hover {
  transform: rotate(-3deg) scale(1.05);
}

.achen4 {
  width: 180px;
  transform: rotate(3deg);
  margin-left: 50px;
}

.achen4:hover {
  transform: rotate(3deg) scale(1.05);
  cursor: pointer;
}

.resume {
  position: absolute;
  width: 200px;
  top: 25%;
  left: 25%;
  z-index: 1;
}

.resume:hover {
  transform: scale(1.05);
}

@media (max-width: 40rem) {
  .main {
    flex-direction: column;
    padding-bottom: 20px;
    text-align: center;
  }

  .list {
    min-width: 390px;
    padding: 0;
  }

  .achen1 {
    width: 40%;
  }

  .achen2 {
    width: 30%;
  }

  .achen3 {
    width: 35%;
    margin-left: 5px;
  }

  .achen4 {
    width: 40%;
  }
}
