.project {
  margin: auto;
  display: block;
  gap: 2rem;
  padding: 2rem;
  text-align: center;
  background-color: #161617;
  color: white;
}

.projectContainer {
  display: grid;
  grid-template-columns: 50% 50%;
  column-gap: 10px;
  height: 500px;
  width: 80%;
  margin: 0 auto;
}

.reactProject {
  border-radius: 5px;
  background-color: black;
  justify-content: center;
}

.newReactProject {
  width: 80%;
  margin: auto;
  height: 80%;
  background-color: white;
  box-shadow: 0px 0px 3px 1px white;
  overflow: hidden;
  color: black;
}

.blenderProject {
  display: grid;
  grid-template-rows: 50% 50%;
}

.blenderProjectOne {
  background-color: black;
  border-radius: 5px;
  justify-content: center;
  overflow: hidden;
}

.blenderProjectTwo {
  margin-top: 10px;
  background-color: black;
  border-radius: 5px;
  justify-content: center;
}

.moreContainer {
  display: flex;
  margin-top: 10px;
}

.resume {
  width: 20%;
  height: 80px;
  margin-left: 10%;
  background-color: rgba(245, 245, 245);
  border-radius: 5px;
}

.moreProject {
  width: 60%;
  height: 80px;
  margin-left: 12px;
  background-color: rgba(245, 245, 245);
  border-radius: 5px;
}

:root {
  --text-color: hsla(210, 50%, 85%, 1);
  --shadow-color: hsla(210, 40%, 52%, 0.4);
  --btn-color: lightseagreen;
  --bg-color: black;
}

.githubButton {
  position: relative;
  padding: 10px 20px;
  border: none;
  background: none;
  cursor: pointer;
  width: 100%;
  height: 100%;

  text-transform: uppercase;
  font-size: 28px;
  color: var(--text-color);

  background-color: var(--btn-color);
  border-radius: 5px;
  z-index: 0;
  overflow: hidden;
  /* display: flex; */
  /* justify-content: center; 
    align-items: center; */
}

.right::after,
.githubButton::after {
  content: "check my other project on github!";
  display: block;
  position: absolute;
  white-space: nowrap;
  padding: 40px 40px;
  pointer-events: none;
}

/* .githubButton::after{
  font-weight: 200;
  top: -18px;
  left: -40px;
  border: 1px solid;
  width: 100%;
}  */

.right,
.left {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}
.right {
  left: 66%;
}
.left {
  right: 66%;
}
.right::after {
  top: -30px;
  left: calc(-66% - 20px);
  width: 100%;

  background-color: var(--bg-color);
  color: transparent;
  transition: transform 0.4s ease-out;
  transform: translate(0, -90%) rotate(0deg);
}

.githubButton:hover .right::after {
  transform: translate(0, -47%) rotate(0deg);
}

.githubButton .right:hover::after {
  transform: translate(0, -50%) rotate(-7deg);
}

.githubButton .left:hover ~ .right::after {
  transform: translate(0, -50%) rotate(7deg);
}

.githubButton::before {
  content: "";
  pointer-events: none;
  opacity: 0.6;
  background: radial-gradient(
      circle at 12% 55%,
      transparent 0,
      transparent 6px,
      #f9c762 3px,
      #f9c762 4px,
      transparent 8px
    ),
    radial-gradient(
      circle at 20% 35%,
      transparent 0,
      transparent 6px,
      #f9c762 3px,
      #f9c762 4px,
      transparent 8px
    ),
    radial-gradient(
      circle at 46% 52%,
      transparent 0,
      transparent 8px,
      #f9c762 5px,
      #f9c762 6px,
      transparent 10px
    ),
    radial-gradient(
      circle at 75% 44%,
      transparent 0,
      transparent 6px,
      #f9c762 3px,
      #f9c762 4px,
      transparent 8px
    ),
    radial-gradient(
      circle at 90% 20%,
      transparent 0,
      transparent 6px,
      #f9c762 3px,
      #f9c762 4px,
      transparent 8px
    );

  width: 100%;
  height: 300%;
  top: 0;
  left: 0;
  position: absolute;
  animation: bubbles 5s linear infinite both;
}

.githubGrid {
  display: grid;
  grid-template-columns: 80% 20%;
  margin-left: 10%;
}

.githubWord {
  display: flex;
  justify-content: center;
  align-items: center;
}
.githubLogo {
  margin-left: -50px;
}

@keyframes bubbles {
  from {
    transform: translate();
  }
  to {
    transform: translate(0, -66.666%);
  }
}

.header {
  padding: 1rem;
  display: flex;
  justify-content: center;
}

.nav {
  width: 200px;
  height: 40px;
  background: lightseagreen;
  border-radius: 100px;
  border: 3px solid lightgray;
  /* transition: 0.2s ease-in-out; */
  transition: 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}

.list {
  display: flex;
  gap: 0.5rem;
  list-style-type: none;
  padding-left: 8px;
  padding-right: 10px;
}

.list a {
  padding: 0.7rem 1.8rem;
  border-radius: 100px;
  text-decoration: none;
  display: flex;
  width: 30px;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.active {
  background-color: #f9c762;
}

.achenOne {
  width: 80px;
  transform: rotate(-3deg);
}

.achenTwo {
  width: 95px;
  transform: rotate(3deg);
  margin-left: 50px;
}

.imageGrid {
  width: 50%;
  height: 50%;
  border: 1px solid;
  /* margin-top: -60px; */
}

.courseManager {
  margin-top: 30px;
  width: 80%;
}

@media (max-width: 40rem) {
  .projectContainer {
    grid-template-columns: 100%;
    margin-bottom: 15px;
  }

  .blenderProject {
    grid-template-columns: 100%;
    margin-top: 10px;
    
  }

  .moreContainer {
    display: grid;
    width: 80%;
    padding-top: 15rem;
    margin: auto;
  }

  .resume {
    width: 100%;
    margin: auto;
    margin-bottom: 10px;
  }

  .moreProject {
    width: 100%;
    margin: auto;
  }

  .githubGrid {
    margin-left: -15px;
  }

  .githubButton {
    font-size: 100%;
  }

  .githubLogo {
    margin-left: -10px;
  }
}
